import React from "react";
import { useState, useEffect } from "react"
import '../App.css';
import { Input, Form, Container } from 'semantic-ui-react'


export default function Lost() {

    return (
        <div>
            <p>You look lost.</p>
            <p>Go to the home page</p>
            <p>Go to the settings page</p>

        </div>
    );
}