import logo from './logo.svg';
import './App.css';

import { Input, Form, Container } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import Settings from '../src/components/settings';
import Main from '../src/components/main';
import Judges from '../src/components/judges';
import Lost from '../src/components/404';
import Red from '../src/components/red';
import Orange from '../src/components/orange';
import Green from '../src/components/green';

import Gold from '../src/components/gold';
import Final from '../src/components/final';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      {/* TODO */}
      {/* 1/ make sure react doesnt open previous opened url (meaning after you submit chilli form 1, when you click link for chill 2, it may use previous url params) */}
      {/* 2/ Set the tasters email! [x]
      3/ Fix the array bug for clickedItems

      // Redirect on typeform completion https://help.typeform.com/hc/en-us/articles/360029257112-Redirect-on-completion-Classic-builder-
        // hidden fields for type form
        chilliname // the chili they are judging
        useremail  // their email
        username // their full name
        */}
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/settings" component={Settings} />
          <Route path="/judges" component={Judges} />
          {/* <Route path="/red" component={Red} /> Red not in 2023 */}
          <Route path="/red" component={Red} /> {/* is in 24 */}
          <Route path="/orange" component={Orange} />
          <Route path="/green" component={Green} />
          <Route path="/gold" component={Gold} />
          <Route path="/final" component={Final} />
          {/* if no match show 404 like page */}
          <Route component={Lost} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
