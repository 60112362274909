import React from 'react';
import { useState, useEffect } from 'react';
import '../App.css';
import { Input, Form, Container, Icon, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function Main() {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState();
  const [userLoggedIn, setuserLoggedIn] = useState(false);
  const [clickedItems, setclickedItems] = useState([]);
  const [completedItems, setcompletedItems] = useState([]);

  const [showMessage, setShowMessage] = useState();

  // const userEmail = 'dom@test.com';
  let chilliName = 'default';

  const logIn = () => {
    console.log('login', userEmail);
    if (userEmail !== '') {
      localStorage.setItem('user', userEmail);

      console.log('---> users name: ', userName);

      localStorage.setItem('userName', userName);
      setuserLoggedIn(true);
    }
  };

  const putItemsInLocalStorage = (item) => {
    console.log('!!!! >>>> putItemsInLocalStorage CALLED');
    const completedItemsFromLocalStorage = JSON.parse(
      localStorage.getItem('completedItems')
    );
    // const completedItemsFromLocalStorage = localStorage.getItem('completedItems');

    console.log(
      '>>> existing completedItemsFromLocalStorage:',
      completedItemsFromLocalStorage
    );
    console.log(`type of ${typeof completedItemsFromLocalStorage}`);

    // this is their first item!
    if (!completedItemsFromLocalStorage) {
      console.log('~~~ FIRST ITEM');
      console.log(
        'completedItemsFromLocalStorage is undefined. This must be their first chili item'
      );
      const firstCompletedItem = [item];

      //set state
      setcompletedItems(firstCompletedItem);

      // JSON.stringify() &  JSON.parse()
      //set local storage
      localStorage.setItem(
        'completedItems',
        JSON.stringify(firstCompletedItem)
      );
    } else {
      console.log('This must be their second (or more) chilli item.');
      console.log(
        `Before editing. completedItemsFromLocalStorage: ${completedItemsFromLocalStorage}`
      );

      console.log(
        '22 >>> existing completedItemsFromLocalStorage:',
        completedItemsFromLocalStorage
      );
      console.log(`22 type of ${typeof completedItemsFromLocalStorage}`);

      console.log(
        `DEBUG JSON completedItemsFromLocalStorage: ${JSON.stringify(
          completedItemsFromLocalStorage
        )}`
      );

      console.log('item', item);
      console.log('types of item', typeof item);

      //set state
      let originalArray = completedItemsFromLocalStorage;
      console.log('originalArray', originalArray);
      console.log(
        'JSON.stringify(originalArray',
        JSON.stringify(originalArray)
      );
      originalArray.push(item);
      // originalArray.push(item);
      // console.log("AFTER THE EDIT ORIGINAL", originalArray);
      // console.log("editedArray done:", editedArray);

      setcompletedItems(originalArray);

      //set local storage
      localStorage.setItem('completedItems', JSON.stringify(originalArray));

      // V1
    }

    console.log('Ive updated completed items');
    setTimeout(() => {
      console.log('completedItems update in state... ', completedItems);
      console.log(
        `localStorage.getItem('completedItems') ${localStorage.getItem(
          'completedItems'
        )}`
      );
      console.log(
        `JSON.stringify() local storage items: ${JSON.stringify(
          localStorage.getItem('completedItems')
        )}`
      );
    }, 3000);
  };

  const setChilliName = (value) => {
    chilliName = value;
    console.log('chilliname is now', chilliName);
  };
  const listOfChillis = [
    { id: '3', n: "Bill Meyers's Chili", name: "Can't Trump This Chili" },
    { id: '6', n: "Jil Donovan's Chili", name: 'Big Bang Chili' },
    { id: '9', n: "Kevin McKee's Chili", name: 'Zamboni Bison Chili' },
    { id: '12', n: "Dan Kay's Chili", name: 'Meaty Beaty Bold and Beefy' },
    { id: '15', n: "Zach Johnson's Chili", name: "Johnson's Meats 2.0" },
    {
      id: '2',
      n: "Kim & Dennis's Chili",
      name: 'It’s The Great Pumpkin Chili',
    },
    { id: '5', n: "JD Englum's Chili", name: 'The Spoonful Chili' },
    {
      id: '8',
      n: "Michelle Roybal's Chili",
      name: "Bobby Booshay's Cajun Chili",
    },
    { id: '11', n: "Natalie Lavalee's Chili", name: "The Lord's Supper" },
    { id: '14', n: "Vaughn/Marky's Chili", name: 'MVP Campfire Chili' },
    { id: '1', n: "Becky & Roy Coakley's Chili", name: 'Hurt So Good' },
    { id: '10', n: "Jourdan Thunberg's Chili", name: "Smoke It Like It's Hot" },
    { id: '13', n: "Tim Meyers's Chili", name: 'Worst Chili Ever' },
    { id: '4', n: "Jason Smith's Chili", name: 'Bartlett Heat' },
    { id: '7', n: "Jimmy Buczynski's Chili", name: 'Jimbo’s Hog Wild Chili' },
  ];
  // all old
  // const listOfChillis = [
  //   { id: '1', n: "JD Englum's Chilli", name: 'All Day Chili' },
  //   { id: '2', n: "Dave Douglas's Chilli", name: 'Sweet Heat' },
  //   { id: '3', n: "Katie Quebbeman's Chilli", name: 'Straight from the Can' },
  //   { id: '4', n: "Pete & Aimee B's Chilli", name: "Don't Fear the Reaper" },
  //   { id: '5', n: "Steve & Debbie H's Chilli", name: 'Hell Hath No Fury' },
  //   { id: '6', n: "Jason Smith's Chilli", name: 'The Bartlett Banger' },
  //   { id: '7', n: "Deanna Thunberg's Chilli", name: 'Borracha Picante' },
  //   {
  //     id: '8',
  //     n: "Jourdan Thunberg's Chilli",
  //     name: "Good 'Til the Last Drop",
  //   },
  //   { id: '9', n: "Don Thunberg's Chilli", name: "Better Than My Wife's" },
  //   {
  //     id: '10',
  //     n: "Brody Roybal's Chilli",
  //     name: 'The Brody Roybal Experience',
  //   },
  //   { id: '11', n: "JJ O'Connor's Chilli", name: "Sweet Baby J's" },
  //   { id: '12', n: "Bob Roybal's Chilli", name: "Can't Beat My Meat" },
  //   { id: '13', n: "Matt Shepherd's Chilli", name: "Ma and Pa's 2.0" },
  //   { id: '14', n: "Bill Meyers's Chilli", name: 'Notorious P.I.G.' },
  //   { id: '15', n: "Becky & Roy C's Chilli", name: 'Sassy Sauce' },
  //   {
  //     id: '16',
  //     n: "Ron & Char Hall's Chilli",
  //     name: 'Stick to the Ribs Chili',
  //   },
  // ];

  const signUserOut = () => {
    console.log('signUserOut');
    setUserName(undefined);
    setUserEmail('');
    localStorage.removeItem('user');
    // console.log("---> users name: ", userName);
    localStorage.removeItem('userName');
    //leave clickedItmes

    setuserLoggedIn(false);

    setShowMessage('logout');
  };

  useEffect(() => {
    console.log('useEffect');
    const user = localStorage.getItem('user'); //email
    const userName = localStorage.getItem('userName');

    if (user) {
      console.log('email is here in local storage');
      setUserEmail(user);

      if (userName) {
        setUserName(userName);
      }

      setuserLoggedIn(true);
    }

    // RENAMING clickedItems to completedItems
    // const itemsClicked = localStorage.getItem('clickedItems');

    const completedItemsLs = localStorage.getItem('completedItems');
    if (completedItemsLs) {
      console.log(
        'USE EFFECT: This user has voted for items. Parse and set state'
      );
      setcompletedItems(JSON.parse(completedItemsLs));

      setTimeout(() => {
        console.log(
          'USE EFFECT: completedItems update in state... ',
          completedItems
        );
      }, 3000);
    }

    console.log(`completedItemsLs >> ${completedItemsLs}`);
    console.log(`completedItemsLs type >> ${typeof completedItemsLs}`);

    console.log(`completedItems >> ${completedItems}`);
    console.log(`completedItems type >> ${typeof completedItems}`);

    const query = new URLSearchParams(window.location.search);
    console.log('url parameters', query);
    console.log(`window.location.search ${window.location.search}`);
    const completed = query.get('completed');
    console.log('completed url parameter: ', completed);

    if (completed) {
      console.log('there is an chili id in completed.', completed);
      putItemsInLocalStorage(completed);
    }

    const userEmailWhoCompletedGrading = query.get('ue');
    console.log(`userEmailWhoCompletedGrading ${userEmailWhoCompletedGrading}`);

    const signout = query.get('signout');
    if (signout == 'true') {
      console.log('there is a parameter in the URL to log out.');
      signUserOut();
    }

    // if (itemsClicked) {
    //   console.log('user had clicked items, update UI')
    //   setclickedItems(itemsClicked);
    // }
  }, []);

  return (
    <header className="App-header">
      <div className="content">
        <div className="top-half"></div>

        <div style={{ height: '30px', padding: 0 }}>
          <img
            src="hot-pepper.png"
            style={{
              height: '90px',
              backgroundColor: 'white',
              position: 'relative',
              top: '-48px',
              padding: 20,
              borderRadius: 60,
            }}
          ></img>
        </div>

        <div className="text-container">
          <h4 className="custom-fonts" style={{ marginTop: 20 }}>
            Welcome to JJ's Chili Cookoff!
          </h4>

          <p style={{ fontSize: 18, paddingBottom: 100 }}>
            Please scan the QR code to get access to your tasting group!
          </p>
        </div>
        {false && (
          <div>
            {!userLoggedIn && (
              <div style={{ width: '100%', height: '40vh', padding: 3 }}>
                <p style={{ fontSize: 17 }}>
                  Tasters, enter your email to get started.
                </p>

                <Container
                  textAlign="left"
                  style={{ padding: '5px 20px 200px 20px' }}
                >
                  <Form>
                    <Form.Field>
                      <label>Full Name</label>
                      <input
                        placeholder="Full Name"
                        onChange={(event) => setUserName(event.target.value)}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Email</label>
                      <input
                        placeholder="BiebsWasHere@gmail.com"
                        onChange={(event) => setUserEmail(event.target.value)}
                      />
                    </Form.Field>

                    <Container textAlign="center">
                      <button className="email-btn" onClick={logIn}>
                        Start
                      </button>
                    </Container>
                    {/* <Button type='submit'>Submit</Button> */}
                  </Form>
                </Container>
              </div>
            )}

            {userLoggedIn && (
              <div>
                <div className="chili-listing-container">
                  <ul className="chilli-listings">
                    {listOfChillis.map(function (item, id) {
                      return (
                        <li className="chilli-listing-item" key={item.id}>
                          <div className="ui grid" style={{ height: 70 }}>
                            {/* ICON */}
                            <div
                              style={{ Top: 20 }}
                              className="two wide column"
                            >
                              {completedItems.includes(item.id) ? (
                                <img
                                  src="pot-icon-done.png"
                                  style={{ height: 35, marginRight: 10 }}
                                ></img>
                              ) : (
                                <img
                                  src="pot-icon.png"
                                  style={{ height: 35, marginRight: 10 }}
                                ></img>
                              )}
                              <span
                                style={{
                                  fontSize: 20,
                                  position: 'relative',
                                  left: 12,
                                  top: -25,
                                  color: 'white',
                                }}
                              >
                                {item.id}
                              </span>
                            </div>

                            {/* TEXT */}
                            <div
                              style={{ paddingTop: 25 }}
                              className="fourteen wide column"
                            >
                              <a
                                className="chili-link"
                                style={
                                  item.name.length > 24
                                    ? { fontSize: '16px' }
                                    : {}
                                }
                                onClick={() => {
                                  setChilliName(item.name);
                                }}
                                href={
                                  'https://form.typeform.com/to/NwRvOUFm#chilliname=' +
                                  item.name +
                                  '&chilliid=' +
                                  item.id +
                                  '&useremail=' +
                                  userEmail +
                                  '&username=' +
                                  userName
                                }
                              >
                                {item.name}
                                {completedItems.includes(item.id) && (
                                  <span style={{ paddingLeft: 8 }}>✅</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                {userEmail && (
                  <em style={{ fontSize: '9px', color: 'grey' }}>
                    Logged in as {userName} ({userEmail})
                  </em>
                )}
                {/* <span>{completedItems}</span> */}
              </div>
            )}
          </div>
        )}

        {showMessage == 'logout' && (
          <Message color="blue">
            {/* <p style={{ fontSize: 13 }}> */}
            Logged out. Please log back in with the same email you used last
            time
            {/* </p> */}
          </Message>
        )}

        <Container textAlign="right">
          <Link to="/settings">
            <Icon
              name="setting"
              size="small"
              style={{
                color: '#dbdbdb',
                position: 'relative',
                right: 10,
                bottom: 10,
              }}
            />
          </Link>
        </Container>
      </div>
    </header>
  );
}
