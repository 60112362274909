import React from 'react';
import { useState, useEffect } from 'react';
import '../App.css';
import { Input, Form, Container, Icon, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// https://form.typeform.com/to/VnltUG2b#chilliname=xxxxx&useremail=xxxxx&chilliid=xxxxx
export default function Judges() {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState();
  const [userLoggedIn, setuserLoggedIn] = useState(false);
  const [clickedItems, setclickedItems] = useState([]);
  const [completedItems, setcompletedItems] = useState([]);

  const [showMessage, setShowMessage] = useState();

  // const userEmail = 'dom@test.com';
  let chilliName = 'default';

  const logIn = () => {
    console.log('login', userEmail);
    if (userEmail !== '') {
      localStorage.setItem('user', userEmail);

      console.log('---> users name: ', userName);

      localStorage.setItem('userName', userName);
      setuserLoggedIn(true);
    }
  };

  const putItemsInLocalStorage = (item) => {
    console.log('!!!! >>>> putItemsInLocalStorage CALLED');
    const completedItemsFromLocalStorage = JSON.parse(
      localStorage.getItem('completedItems')
    );
    // const completedItemsFromLocalStorage = localStorage.getItem('completedItems');

    console.log(
      '>>> existing completedItemsFromLocalStorage:',
      completedItemsFromLocalStorage
    );
    console.log(`type of ${typeof completedItemsFromLocalStorage}`);

    // this is their first item!
    if (!completedItemsFromLocalStorage) {
      console.log('~~~ FIRST ITEM');
      console.log(
        'completedItemsFromLocalStorage is undefined. This must be their first chili item'
      );
      const firstCompletedItem = [item];

      //set state
      setcompletedItems(firstCompletedItem);

      // JSON.stringify() &  JSON.parse()
      //set local storage
      localStorage.setItem(
        'completedItems',
        JSON.stringify(firstCompletedItem)
      );
    } else {
      console.log('This must be their second (or more) chilli item.');
      console.log(
        `Before editing. completedItemsFromLocalStorage: ${completedItemsFromLocalStorage}`
      );

      console.log(
        '22 >>> existing completedItemsFromLocalStorage:',
        completedItemsFromLocalStorage
      );
      console.log(`22 type of ${typeof completedItemsFromLocalStorage}`);

      console.log(
        `DEBUG JSON completedItemsFromLocalStorage: ${JSON.stringify(
          completedItemsFromLocalStorage
        )}`
      );

      console.log('item', item);
      console.log('types of item', typeof item);

      //set state
      let originalArray = completedItemsFromLocalStorage;
      console.log('originalArray', originalArray);
      console.log(
        'JSON.stringify(originalArray',
        JSON.stringify(originalArray)
      );
      originalArray.push(item);
      // originalArray.push(item);
      // console.log("AFTER THE EDIT ORIGINAL", originalArray);
      // console.log("editedArray done:", editedArray);

      setcompletedItems(originalArray);

      //set local storage
      localStorage.setItem('completedItems', JSON.stringify(originalArray));

      // V1
    }

    console.log('Ive updated completed items');
    setTimeout(() => {
      console.log('completedItems update in state... ', completedItems);
      console.log(
        `localStorage.getItem('completedItems') ${localStorage.getItem(
          'completedItems'
        )}`
      );
      console.log(
        `JSON.stringify() local storage items: ${JSON.stringify(
          localStorage.getItem('completedItems')
        )}`
      );
    }, 3000);
  };

  const setChilliName = (value) => {
    chilliName = value;
    console.log('chilliname is now', chilliName);
  };

  const listOfChillis = [
    { id: '3', name: 'Don Thunberg Chili', c: 'Chicken Choker Chili' },
    {
      id: '7',
      name: 'Millie Thunberg Chili',
      c: 'Mama Bear’s Bear Down Chili',
    },
    {
      id: '11',
      name: 'Franco Calzante Chili',
      c: "Papi's South Of The Border Chili",
    },
    {
      id: '15',
      name: 'Gillian Falknor Chili',
      c: 'Last Minute Success',
    },
    { id: '2', name: 'Jamie Mendoza Chili', c: 'Brown Chicken Brown Cow' },
    { id: '6', name: 'Zach Johnson Chili', c: "Johnson's Last Stand" },
    { id: '10', name: 'JJ OConnor Chili', c: 'Mystery Mayhem' },
    {
      id: '14',
      name: 'Jimmy Buczynski Chili',
      c: 'Jim Buckeroo’s Buffalo Chili',
    },
    { id: '4', name: 'Becky Coakley Chili', c: 'Chili Chili Bang Bang' },
    { id: '8', name: 'Matt Shepherd Chili', c: 'Mom and Pops' },
    {
      id: '12',
      name: 'Michael Perrino Chili',
      c: 'Oppenheimer’s Atom Bomb Chili',
    },
    { id: '16', name: 'Natalie Lavallee Chili', c: 'Slow Burn' },
    { id: '1', name: 'Joey Calzante Chili', c: 'Bada Bing Chili Wit Zing' },
    { id: '5', name: 'Vaughn Parcel Chili', c: 'Fire on the Mountain' },
    { id: '9', name: 'Christie Mendoza Chili', c: 'Carnivore Chili' },
    { id: '13', name: 'Vanessa Hastings Chili', c: 'Tasty Hastings' },
  ];

  const signUserOut = () => {
    console.log('signUserOut');
    setUserName(undefined);
    setUserEmail('');
    localStorage.removeItem('user');
    // console.log("---> users name: ", userName);
    localStorage.removeItem('userName');
    //leave clickedItmes

    setuserLoggedIn(false);

    setShowMessage('logout');
  };

  useEffect(() => {
    console.log('useEffect');
    const user = localStorage.getItem('user'); //email
    const userName = localStorage.getItem('userName');

    if (user) {
      console.log('email is here in local storage');
      setUserEmail(user);

      if (userName) {
        setUserName(userName);
      }

      setuserLoggedIn(true);
    }

    // RENAMING clickedItems to completedItems
    // const itemsClicked = localStorage.getItem('clickedItems');

    const completedItemsLs = localStorage.getItem('completedItems');
    if (completedItemsLs) {
      console.log(
        'USE EFFECT: This user has voted for items. Parse and set state'
      );
      setcompletedItems(JSON.parse(completedItemsLs));

      setTimeout(() => {
        console.log(
          'USE EFFECT: completedItems update in state... ',
          completedItems
        );
      }, 3000);
    }

    console.log(`completedItemsLs >> ${completedItemsLs}`);
    console.log(`completedItemsLs type >> ${typeof completedItemsLs}`);

    console.log(`completedItems >> ${completedItems}`);
    console.log(`completedItems type >> ${typeof completedItems}`);

    const query = new URLSearchParams(window.location.search);
    console.log('url parameters', query);
    console.log(`window.location.search ${window.location.search}`);
    const completed = query.get('completed');
    console.log('completed url parameter: ', completed);

    if (completed) {
      console.log('there is an chili id in completed.', completed);
      putItemsInLocalStorage(completed);
    }

    const userEmailWhoCompletedGrading = query.get('ue');
    console.log(`userEmailWhoCompletedGrading ${userEmailWhoCompletedGrading}`);

    const signout = query.get('signout');
    if (signout == 'true') {
      console.log('there is a parameter in the URL to log out.');
      signUserOut();
    }

    // if (itemsClicked) {
    //   console.log('user had clicked items, update UI')
    //   setclickedItems(itemsClicked);
    // }
  }, []);

  return (
    <header className="App-header">
      <div className="content">
        <div className="top-half"></div>

        <div style={{ height: '30px', padding: 0 }}>
          <img
            src="hot-pepper.png"
            style={{
              height: '90px',
              backgroundColor: 'white',
              position: 'relative',
              top: '-48px',
              padding: 20,
              borderRadius: 60,
            }}
          ></img>
        </div>

        <div className="text-container">
          <h4 className="custom-fonts" style={{ marginTop: 20 }}>
            Judges Scoring
          </h4>
          {userLoggedIn && (
            <p style={{ fontSize: 18 }}>
              Judges, please score each chilli below.
            </p>
          )}
        </div>

        {!userLoggedIn && (
          <div style={{ width: '100%', height: '30vh', padding: 3 }}>
            <p style={{ fontSize: 17 }}>
              Judges, enter your email to get started.
            </p>

            <Container
              textAlign="left"
              style={{ padding: '5px 20px 200px 20px' }}
            >
              <Form>
                <Form.Field>
                  <label>Full Name</label>
                  <input
                    placeholder="Full Name"
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Email</label>
                  <input
                    placeholder="BiebsWasHere@gmail.com"
                    onChange={(event) => setUserEmail(event.target.value)}
                  />
                </Form.Field>

                <Container textAlign="center">
                  <button className="email-btn" onClick={logIn}>
                    Start
                  </button>
                </Container>
                {/* <Button type='submit'>Submit</Button> */}
              </Form>
            </Container>
          </div>
        )}

        {userLoggedIn && (
          <div>
            <div className="chili-listing-container">
              <ul className="chilli-listings">
                {listOfChillis.map(function (item, id) {
                  return (
                    <li className="chilli-listing-item" key={item.id}>
                      <div className="ui grid" style={{ height: 70 }}>
                        {/* ICON */}
                        <div style={{ Top: 20 }} className="two wide column">
                          {completedItems.includes(item.id) ? (
                            <img
                              src="pot-icon-done.png"
                              style={{ height: 35, marginRight: 10 }}
                            ></img>
                          ) : (
                            <img
                              src="pot-icon.png"
                              style={{ height: 35, marginRight: 10 }}
                            ></img>
                          )}
                          <span
                            style={{
                              fontSize: 16,
                              position: 'relative',
                              left: item?.id?.length === 2 ? 10 : 15,
                              top: -27,
                              color: 'white',
                            }}
                          >
                            {item.id}
                          </span>
                        </div>

                        {/* TEXT */}
                        <div
                          style={{ paddingTop: 25 }}
                          className="fourteen wide column"
                        >
                          <a
                            className="chili-link"
                            onClick={() => {
                              setChilliName(item.id);
                            }}
                            href={
                              'https://form.typeform.com/to/KmCV9aZb#chilliid=' +
                              item.id +
                              '&useremail=' +
                              userEmail +
                              '&username=' +
                              userName
                            }
                          >
                            Chili #{item.id}
                            {completedItems.includes(item.id) && (
                              <span style={{ paddingLeft: 8 }}>✅</span>
                            )}
                          </a>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            {userEmail && (
              <em style={{ fontSize: '9px', color: 'grey' }}>
                Logged in as {userName} ({userEmail})
              </em>
            )}
            {/* <span>{completedItems}</span> */}
          </div>
        )}

        {showMessage == 'logout' && (
          <Message color="blue">
            {/* <p style={{ fontSize: 13 }}> */}
            Logged out. Please log back in with the same email you used last
            time
            {/* </p> */}
          </Message>
        )}

        <Container textAlign="right">
          <Link to="/settings">
            <Icon
              name="setting"
              size="small"
              style={{
                color: '#dbdbdb',
                position: 'relative',
                right: 10,
                bottom: 10,
              }}
            />
          </Link>
        </Container>
      </div>
    </header>
  );
}
