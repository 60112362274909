import React from 'react';
import '../App.css';
import { Input, Form, Container } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Settings() {
  return (
    <div className="App">
      <div style={{ paddingTop: 50 }}>
        <p>Setttings</p>

        <Link to="/?signout=true">
          <button className="email-btn">Log out.</button>
        </Link>
      </div>
    </div>
  );
}
